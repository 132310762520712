import i18n from '@/libs/i18n'

const getVocabWords = curriculum => {
  const { generated_words } = curriculum
  const vocabs = []
  generated_words.forEach(item => {
    const { synonyms } = item
    vocabs.push({
      word_in_eng: item.word,
      synonym_1: synonyms[0]?.text,
      synonym_2: synonyms[1]?.text,
      imageLibraryId: item.id,
      data: {},
    })
  })
  return vocabs
}

const getwordnetWords = (mainText, index = null) => {
  let newText = mainText.replace(/\**/g, '')
    // eslint-disable-next-line no-useless-escape
    .replace(/\##/g, '')
    .replaceAll(/@@@(.*?)@@@/g, (match, word) => `
        <button class="btn btn-danger youtube-video-button" link="${word}">
         <i class="fa fa-play-circle"></i> ${i18n.tc('labels.play-video')}
        </button>
        `)
  if (index !== null) {
    newText = `${index + 1}. ${newText}`
  }
  return newText
}

export {
  // eslint-disable-next-line import/prefer-default-export
  getVocabWords,
  getwordnetWords,
}
