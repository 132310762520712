<template>

  <div
    style="max-width:1200px"
    class="mx-auto p-2"
  >

    <header class="text-center">
      <div class="row">
        <div class="col-md-12 d-flex flex-row align-items-center ">
          <h2 class="m-0 ml-1 p-0">
            {{ $t('essay-builder-module.essay-builder') }}
          </h2>
        </div>
        <!-- /.col-md-12 -->
      </div>
      <!-- /.row -->
    </header>
    <div class="mt-1">
      <b-tabs
        v-model="activeTab"
        align="right"
      >
        <b-tab
          active
          :title="$t('essay-builder-module.edit-mode')"
        >
          <div>
            <b-alert
              variant="success"
              :show="!essay.editable"
            >
              <div class="alert-body d-flex justify-content-between align-items-center">
                <p>
                  <strong>{{ essay.reviewed_by }}</strong> reviewed the essay on  <strong>{{ essay.reviewed_at }}</strong> and gave a score of <strong> {{ essay.score }}</strong>.
                </p>
                <b-button
                  v-if="!essay.has_pending_request"
                  variant="primary"
                  type="submit"
                  class="mr-1"
                  :disabled="isProcessing"
                  @click="requestEditAccess()"
                >
                  {{ $t('essay-builder-module.request-edit-access') }}
                </b-button>
                <b-button
                  v-else
                  variant="secondary"
                  type="submit"
                  class="mr-1"
                  disabled
                >
                  {{ $t('essay-builder-module.request-pending') }}
                </b-button>
              </div>

            </b-alert>
            <image-selector
              v-if="allImages.length>0"
              :images="allImages"
              :essay-images="essay.images"
              :fetching-hints="hintsBeingFetched"
              :is-editable="essay.editable"
              @imagesSelected="(img)=>essay.images = img"
            />
            <introduction
              :is-editable="essay.editable"
              :hints="hints"
              :data="essay.introduction"
              :fetching-hints="hintsBeingFetched"
              @getHint="getHint"
            />
            <body-section
              v-for="(body,index) in essay.body"
              :key="index"
              :is-editable="essay.editable"
              :index="index+1"
              :data="body"
              :hints="hints"
              :fetching-hints="hintsBeingFetched"
              @getHint="getHint"
            />
            <conclusion
              :is-editable="essay.editable"
              :hints="hints"
              :data="essay.conclusion"
              :fetching-hints="hintsBeingFetched"
              @getHint="getHint"
            />
          </div>
          <b-alert
            :variant="isSavedAsDraft ? 'primary' : 'success'"
            :show="savedAs!=null && essay.editable"
          >
            <div class="alert-body">
              <span>{{ isSavedAsDraft ? $t('essay-builder-module.essay-has-been-saved-as-a-draft') : $t('essay-builder-module.essay-has-been-published') }} </span>
            </div>
          </b-alert>
          <div
            v-if="essay.editable"
            class="row"
          >
            <div class="col-md-12 d-flex flex-row align-items-center justify-content-end">
              <b-button
                v-if="!hasBeenPublished"
                variant="primary"
                type="submit"
                class="mr-1"
                :disabled="isProcessing"
                @click="handleSubmit('is_draft')"
              >
                {{ isSavedAsDraft ? $t('essay-builder-module.update-draft'): $t('essay-builder-module.save-as-draft') }}
              </b-button>
              <b-button
                v-if="!hasBeenPublished"
                variant="warning"
                type="submit"
                class="mr-1"
                :disabled="isProcessing"
                @click="handleSubmit(isSavedAsForTeacherReview ? 'is_draft' :'for_teacher_review')"
              >
                {{ isSavedAsForTeacherReview ? $t('essay-builder-module.cancel-for-teacher-review'): $t('essay-builder-module.submit-for-teacher-review') }}
              </b-button>
              <b-button
                v-if="hasBeenPublished"
                variant="success"
                :disabled="isProcessing"
                type="submit"
                class="mr-1"
                @click="handleSubmit('is_published')"
              >
                {{ $t('essay-builder-module.update-essay') }}
              </b-button>
              <b-button
                v-if="!hasBeenPublished && savedAs && canPublish"
                variant="success"
                :disabled="isProcessing"
                type="submit"
                @click="showPublishModal()"
              >
                {{ $t('essay-builder-module.publish-article') }}
              </b-button>
            </div>
            <!-- /.col-md-12 -->
          </div>
        </b-tab>
        <b-tab
          :title="$t('essay-builder-module.publish-mode')"
        >
          <preview :essay="essay" />
        </b-tab>
      </b-tabs>

    </div>

    <create-article-modal
      :show="showShouldPublishModal"
      :is-processing="isProcessing"
      @close="showShouldPublishModal=false"
      @yes="publishEssay(true)"
      @no="publishEssay(false)"
    />

  </div>
</template>

<script>
import {
  BTabs, BTab, BButton, BAlert,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { debounce } from 'lodash'
import ImageSelector from './components/ImageSelector.vue'
import Introduction from './components/Introduction.vue'
import Conclusion from './components/Conclusion.vue'
import BodySection from './components/Body.vue'
import Preview from './components/Preview.vue'
import CreateArticleModal from './components/CreateArticleModal.vue'

export default {
  components: {
    ImageSelector, Introduction, BTabs, BTab, BodySection, Conclusion, Preview, BButton, BAlert, CreateArticleModal,
  },
  data() {
    return {
      curriculumId: null,
      lessonId: null,
      classId: null,
      id: null,
      showShouldPublishModal: false,
      hints: {},
      isProcessing: false,
      hintsBeingFetched: [],
      activeTab: 0,
      savedAs: null,
      allImages: [],
      curriculumPageInformation: {},
      canPublish: false,
      essay: {
        images: [],
        editable: true,
        introduction: {
          general_statement: '',
          main_points: ['', '', ''],
          thesis_statement: '',
        },
        body: [
          {
            main_point: '',
            evidences: ['', '', ''],
            connect_to_thesis: '',
            transition_sentence: '',
          },
          {
            main_point: '',
            evidences: ['', '', ''],
            connect_to_thesis: '',
            transition_sentence: '',
          },
          {
            main_point: '',
            evidences: ['', '', ''],
            connect_to_thesis: '',
            transition_sentence: '',
          },
        ],
        conclusion: {
          thesis_statement: '',
          main_points: ['', '', ''],
          closing_sentence: '',
        },
      },
    }
  },
  computed: {
    isSavedAsForTeacherReview() {
      return this.savedAs === 'for_teacher_review'
    },
    isSavedAsDraft() {
      return this.savedAs === 'is_draft' || this.savedAs === 'for_teacher_review'
    },
    hasBeenPublished() {
      return this.savedAs === 'is_published'
    },
  },
  watch: {
    essay: {
      handler: debounce(function () {
        this.handleSubmit(null)
      }, 1000),
      deep: true,
      // lazy: true,
    },
    '$route.params.curriculumId': {
      handler(id) {
        this.curriculumId = id
      },
      deep: true,
      immediate: true,
    },
    '$route.params.classId': {
      handler(id) {
        this.classId = id
      },
      deep: true,
      immediate: true,
    },
    '$route.params.lessonId': {
      handler(id) {
        this.lessonId = id || this.$attrs.curriculum?.lesson_id
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getEssay()
    this.getCurriculumImages()
  },
  methods: {
    showPublishModal() {
      // this.$refs.essayValidator.validate().then(result => {
      //   if (!result) return
      // })
      this.showShouldPublishModal = true
    },
    requestEditAccess() {
      this.isProcessing = true
      useJwt.requestEssayEditAccess(this.lessonId).then(res => {
        this.essay.has_pending_request = true
        this.isProcessing = false
        this.showSuccessMessage(res)
      })
    },
    getHint(page, data) {
      this.isProcessing = true
      let key = `${data.type}_${data.order_index}`
      if (data.type === 'ANS') key = `${data.type}_${page}_${data.order_index}`
      this.hintsBeingFetched.push(`${data.type}_${page}_${data.order_index}`)
      const params = {
        params: {
          curriculum_id: this.curriculumId,
          ...data,
        },
      }
      if (page) params.params.page_index = page
      useJwt.getHint(params).then(res => {
        this.$set(this.hints, key, res ? res.data.data : 'No hints available')
      }).catch(() => {
        this.$set(this.hints, key, 'No hints available')
      }).finally(() => {
        this.hintsBeingFetched.splice(this.hintsBeingFetched.indexOf(key), 1)
        this.isProcessing = false
      })
    },
    publishEssay(createArticle) {
      this.updateEssay('is_published', createArticle)
    },
    handleSubmit(status) {
      if (!status) {
        if (this.essay.id) this.updateEssay(status)
        else this.createEssay('is_draft')
        return
      }
      // eslint-disable-next-line no-unused-vars

      if (this.essay.id) this.updateEssay(status)
      else this.createEssay(status)
    },
    getCurriculumOrderIndex() {
      this.isProcessing = true
      useJwt.getCurriculumOrderIndex(this.curriculumId).then(res => {
        const info = res.data.data
        this.essay.body = []
        this.essay.introduction.main_points = Array(info.total_main_point).join('.').split('.')
        this.essay.conclusion.main_points = Array(info.total_main_point).join('.').split('.')
        for (let i = 0; i < info.total_main_point; i += 1) {
          this.essay.body.push({
            main_point: '',
            evidences: Array(info.pages[i].set_count).join('.').split('.'),
            connect_to_thesis: '',
            transition_sentence: '',
          })
        }
      }).finally(() => {
        this.isProcessing = false
      })
    },

    getEssay() {
      this.isProcessing = true
      useJwt.getEssay({ params: { lesson_id: this.lessonId, class_id: this.classId } }).then(res => {
        if (res.data.data) {
          this.essay = res.data.data.content
          this.essay.id = res.data.data.id
          this.essay.editable = res.data.data.editable
          this.essay.reviewed_at = res.data.data.reviewed_at
          this.essay.reviewed_by = res.data.data.reviewed_by
          this.essay.score = res.data.data.score
          const lastRequest = res.data.data.latest_request
          this.essay.has_pending_request = lastRequest != null || (lastRequest?.granted === 0 && lastRequest?.rescored === 0)
          this.savedAs = res.data.data.status
          // this.canPublish = !!res.data.data?.teacher
          this.canPublish = true
        } else {
          this.getCurriculumOrderIndex()
        }
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getCurriculumImages() {
      this.isProcessing = true
      useJwt.getCurriculumImages(this.curriculumId).then(res => {
        this.allImages = res.data.data ? res.data.data.map(e => e.content) : []
      }).finally(() => {
        this.isProcessing = false
      })
    },
    createEssay(status) {
      this.isProcessing = true
      useJwt.createEssay({
        lesson_id: this.lessonId, class_id: this.classId || this.$route.query.classId, curriculum_id: this.curriculumId, status, content: this.essay,
      }).then(res => {
        this.showSuccessMessage(res)
        this.essay.id = res.data.data.id
        this.savedAs = status
        // this.canPublish = !!res.data.data.teacher
        this.canPublish = true
      }).finally(() => {
        this.isProcessing = false
      })
    },
    updateEssay(status, createArticle = false) {
      this.isProcessing = true
      useJwt.updateEssay(this.essay.id, {
        id: this.essay.id,
        lesson_id: this.lessonId,
        curriculum_id: this.curriculumId,
        status,
        content: this.essay,
        class_id: this.classId || this.$route.query.classId,
        create_article: createArticle,
      }).then(res => {
        this.savedAs = status
        this.showShouldPublishModal = false
        // this.canPublish = !!res.data.data.teacher
        this.canPublish = true
        this.showSuccessMessage(res)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },

}
</script>

<style scoped>
header {
  padding: 10px 0px;
}
</style>
<style lang="scss">
.hint-icon{
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 4;
  cursor: pointer;
}
textarea + .hint-icon{
  right: 23px;
  top: 28px;
}

.form-group div > .hint-icon{
  top: 28px;
}
.green-layout ,.semi-light-layout ,.brown-layout {
  .alert-body{
    color:white;
  }

.form-control{
  color: black !important;
  font-weight: 500;
}
}
</style>
