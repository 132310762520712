<template>
  <swiper :options="swiperOption"
          class="ebook-vocab-card"
  >
    <swiper-slide v-for="chunk,idx of vocabChunks"
                  :key="idx"
    >
      <div class="row"
           :class="{
             'pb-2': canHaveChunk,
           }"
      >
        <div
          v-for="(vocab, index) in chunk"
          :key="index"
          class="col-md-6"
          :style="`margin-top:${index >= 2 ? '60px' : '0'}`"
        >
          <div class="list-style">
            <div class="list-style-top">
              <p
                :id="`vocab-${index}-${idx}`"
                class="mb-0 cursor-pointer on-hover-library"
                @click.stop="getImageLibrary(vocab.imageLibraryId)"
              >
                {{ vocab.word_in_eng }}
              </p>
              <p>
                {{ vocab.word_in_jap }}
              </p>
              <b-popover
                :ref="`popoverRef-${index}-${idx}`"
                :target="`vocab-${index}-${idx}`"
                triggers="click"
                custom-class="w-480 ebook-popover"
              >
                <b-overlay :show="isLoading">
                  <div v-if="Object.keys(vocab.data).length">
                    <vocab-card-data
                      :index="index"
                      :vocab-data="vocab.data"
                      @close-popover="index => closePopover(index, idx)"
                    />
                  </div>
                  <div
                    v-else
                    class="p-1 position-relative"
                  >
                    <p class="mb-0">
                      Sorry, no data available
                    </p>
                    <div class="icon-btn bg-secondary d-flex justify-content-center align-items-center text-light position-absolute top-0 right-0">
                      <feather-icon
                        icon="XIcon"
                        class="cursor-pointer"
                        size="18"
                        @click="closePopover(index, idx)"
                      />
                    </div>
                  </div>
                </b-overlay>
              </b-popover>
            </div>
            <div class="list-style-bottom">
              <p>{{ vocab.synonym_1 }} <br>{{ vocab.synonym_2 }}</p>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <div
      slot="pagination"
      class="swiper-pagination"
    />
  </swiper>
</template>

<script>
import { BPopover, BOverlay } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import _ from 'lodash'
import useJwt from '@/auth/jwt/useJwt'
import VocabCardData from './VocabCardData.vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    BPopover,
    BOverlay,
    VocabCardData,
    Swiper,
    SwiperSlide,
  },
  props: {
    vocabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: true,
      perChunks: 6,
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: false,
        },
      },
    }
  },
  computed: {
    vocabChunks() {
      return _.chunk(this.vocabs, this.perChunks)
    },
    canHaveChunk() {
      return this.vocabs.length > this.perChunks
    },
  },
  methods: {
    getImageLibrary(id) {
      const index = this.vocabs.findIndex(i => i.imageLibraryId === id)
      if (!this.vocabs[index].data.id) {
        this.isLoading = true
        useJwt.getImageLibraryById(this.vocabs[index].imageLibraryId, {
          params: {
            word: this.vocabs[index].word_in_eng,
          },
        }).then(response => {
          const vocabWord = this.vocabs.find(vocab => vocab.word_in_eng?.toLowerCase() === response.data.data.vocab_word?.toLowerCase())
          vocabWord.data = response.data.data
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
    closePopover(index, idx) {
      this.$refs[`popoverRef-${index}-${idx}`][0].$emit('close')
    },
  },
}
</script>

<style lang="scss">
 .w-480 {
  max-width: 480px !important;
}
.top-0 {
  top: -8px;
}
.right-0 {
  right: -12px;
}
.list-style-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}
.list-style-wrapper p {
  font-weight: 600;
}
.list-style {
  text-align: center;
  width: 100%;
  float: left;
}
.list-style-top {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: #29658f;
  padding: 10px;
}
.list-style-bottom {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background: #e8937d;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 1px;
}
.list-style-top p {
  font-size: 16px;
  line-height: normal;
  margin-bottom: 0px;
}
.list-style-bottom p {
  color: #000000;
}
.list-style-2 {
  float: right;
}
.list-style-box-2 {
  margin-top: 60px;
  margin-bottom: 60px;
}
.swiper-pagination-bullets {
  bottom: 0px;
}
</style>
